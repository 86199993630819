<template>
    <div>
        <h2>Coordenadas - {{$route.params.id}}</h2>
        <vue-element-loading :active="loading" style="height: 200px">
            <img src="../assets/pikachu.gif" height="55px" />
        </vue-element-loading>
        <div class="nota">
            <i class="fa fa-info-circle"></i>
            As coordenadas mais novas vão aparecendo abaixo.
        </div>
        <div class="list-group">
            <div class="list-group-item" v-for="(m, index) in mensagens" :key="index">
                <div class="coord-content">
                    <div class="coord-texts">
                        <div class="city">{{ m.datacad | corrigeData }}</div>
                        <div class="coord-text" :style="
                          select.filter(i => i === m.key).length
                            ? 'text-decoration: line-through;'
                            : ''
                        ">
                            {{ m.coords }}
                        </div>
                    </div>
                    <div class="coord-btn">
                        <vue-flip active-click width="100px" height="45px" class="btn-content" v-model="m.copy">
                            <template v-slot:front>
                                <div class="btn" @click="getCopy(m)">
                                    {{ $t('locais.copiar') }}
                                </div>
                            </template>
                            <template v-slot:back>
                                <div class="btn" @click="getCopy(m)">=)</div>
                            </template>
                        </vue-flip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueFlip from 'vue-flip'
import moment from 'moment'
import VueElementLoading from 'vue-element-loading'
import { onValue } from "firebase/database"
import { getAnalytics, logEvent } from 'firebase/analytics'

import CoordsDataService from '../services/services'

export default {
    components: { VueFlip, VueElementLoading },

    data: () => ({
        mensagens: [],
        currentCoords: null,
        currentIndex: -1,
        loading: false,
        select: []
    }),
    filters: {
        corrigeData(x) {
            return moment(x).format('DD/MM/YYYY HH:mm:ss')
        }
    },
    methods: {
        onDataChange(items) {
            let _mensagens = []
            items.forEach(item => {
                let key = item[0]
                let data = item[1]
                _mensagens.push({
                    key: key,
                    coords: data.coords,
                    datacad: data.datacad,
                    copy: false,
                    user: data.user
                })
            })

            //_mensagens.reverse()
            this.loading = false
            this.mensagens = _mensagens.filter(i => i.user === this.$route.params.id)
        },

        refreshList() {
            this.currentCoords = null
            this.currentIndex = -1
        },
        getCopy(coord) {
            if (!coord.copy) {
                this.$copyText(coord.coords).then(
                    () => null,
                    () => alert('Erro')
                )
                const index = this.select.indexOf(coord.key)
                if (index === -1) {
                    this.select.push(coord.key)
                }
            } else {
                const index = this.select.indexOf(coord.key)
                if (index > -1) {
                    this.select.splice(index, 1)
                }
            }
            //coord.copy = !coord.copy
        }
    },
    async mounted() {
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'Coords'
        })
        this.loading = true
        const x = await CoordsDataService.getAll()
        onValue(x, (snapshot) => {
            const data = snapshot.val()
            this.onDataChange(Object.entries(data))
        })
    },
    beforeDestroy() {
        CoordsDataService.off()
    }
}
</script>

<style lang="scss" scoped>
h2 {
    display: flex;
    justify-content: space-between;
    color: #673bb7;
    margin: 10px 0;
}

.btn {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.btn-content {
    margin: 0 auto;
}

.coord-content {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;

    .coord-texts {
        flex: 2;
        display: flex;
        align-items: center;

        .city {
            color: #2c3e50;
            font-size: 12px;
            text-align: center;
            font-weight: bold;
            width: 85px;
        }

        .coord-text {
            color: #2c3e50;
            font-size: 14px;
        }
    }

    .coord-btn {
        flex: 1;

        a {
            display: flex;
            width: 80px;
            color: #fff;
            background-color: #673ab7;
            text-decoration: none;
            padding: 10px 15px;
        }
    }
}
</style>