import { render, staticRenderFns } from "./Coords.vue?vue&type=template&id=ddd5e03a&scoped=true"
import script from "./Coords.vue?vue&type=script&lang=js"
export * from "./Coords.vue?vue&type=script&lang=js"
import style0 from "./Coords.vue?vue&type=style&index=0&id=ddd5e03a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddd5e03a",
  null
  
)

export default component.exports